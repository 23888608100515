/**
 * TextSplit
 */
export default class TextSplit {
  /**
   *
   * @param {object} targetElem
   * @param {string} tagName
   * @param {string} className
   */
  constructor(targetElem, tagName, className) {
    this.targetElem = targetElem;
    this.tagName = tagName;
    this.className = className;
  }

  /**
   * get HTML tagging string
   * @return {string} tagging html string
   */
  getSplitContent() {
    const textContents = this.targetElem.textContent.trim().split('');
    return textContents.reduce((prev, current, index) => {
      prev =
        index == 1 ?
          `<${this.tagName} class="${this.className}">${prev}</${this.tagName}>` :
          prev;
      current = current == ' ' ? '&nbsp;' : current;
      return (
        prev +
        `<${this.tagName} class="${this.className}">${current}</${this.tagName}>`
      );
    });
  }
}
