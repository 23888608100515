import MobileMenu from "./mobile_menu.ts";
import TextSplit from "./text.ts";
import anime from "animejs/lib/anime.es.js";
import fileSearch from "./file_search.ts";

/*
 * Animationn
 */
document.addEventListener("DOMContentLoaded", (event) => {
  fileSearch();

  /*
   * Only Mobile device animation
   */
  if (document.body.clientWidth < 768) {
    /*
     * mobile menu
     */
    new MobileMenu();

    /*
     * 交差オブザーバー API
     * @see https://developer.mozilla.org/ja/docs/Web/API/Intersection_Observer_API
     *
     * IntersectionObserver
     * @see https://developer.mozilla.org/ja/docs/Web/API/IntersectionObserver
     *
     * IntersectionObserver()コンストラクタの引数(callback, options)
     * @see https://developer.mozilla.org/ja/docs/Web/API/IntersectionObserver/IntersectionObserver
     *
     * callbackは2つの引数をとる
     * entries: IntersectionObserverEntry
     * observer: IntersectionObserver
     * @see https://developer.mozilla.org/ja/docs/Web/API/IntersectionObserver/IntersectionObserver
     *
     * ※ position: absoluteを指定すると想定した動作にならない _layout.scssの.site-wrapper--topを参照
     */

    /*
     * IntersectionObserver
     *
     */
    const intersectionObserverCallbak = (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          // .panel-header__title animation
          const panelHeaderTitle = entry.target.querySelector(
            ".panel-header__title"
          );
          if (panelHeaderTitle != undefined) {
            const panelHeaderTitleSplitContent = new TextSplit(
              panelHeaderTitle,
              "span",
              "panel-header__title--animation"
            );
            panelHeaderTitle.innerHTML =
              panelHeaderTitleSplitContent.getSplitContent();
            const headerTitleAnimationSpans = entry.target.querySelectorAll(
              ".panel-header__title--animation"
            );
            /*
             * anime
             * @see https://animejs.com/
             */
            anime({
              targets: headerTitleAnimationSpans,
              translateY: -25,
              delay: anime.stagger(140),
            });
          }

          // circle animation
          const captionNumber = entry.target.querySelector(".caption-number");
          captionNumber?.classList.add("caption-number--animation");
        }
      });
    };

    /*
     * Create IntersectionObserver
     * https://developer.mozilla.org/ja/docs/Web/API/Intersection_Observer_API
     */
    // @see https://cacoo.com/diagrams/i5FItT5GWFYYcK1U/44C0B
    // rootMarginはCSSのmarginプロパティと同様の指定ができる
    // -250px 0px -250px ==> 上 左右 下
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.2],
    };
    const intersectionObserver = new IntersectionObserver(
      intersectionObserverCallbak,
      options
    );

    // Set box to obverve
    const boxes = document.querySelectorAll(
      ".box:not(.box--no-animation), .box--full:not(.box--no-animation)"
    );
    boxes.forEach((box) => {
      intersectionObserver.observe(box);
    });
  }
});
