/**
 * File search
 * @param {HTMLElement} input input要素
 */
export default function filesearch() {
  if (location.href.indexOf('docs/index.html') > -1) {
    const input = document.querySelector('#search-word');
    const lists = document.querySelectorAll('#document-index li');
    input.addEventListener('input', (event) => {
      const searchWord = input.value;
      lists.forEach((list) => {
        if (
          list.innerText.toLowerCase().indexOf(searchWord.toLowerCase()) === -1
        ) {
          list.style.display = 'none';
        } else {
          list.style.display = 'block';
        }
      });
    });
  }
}
